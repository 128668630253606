import React from 'react'
import replace from 'lodash/fp/replace'

import { countPrice } from '../../lib/product'

const recountTransportPrice = (
  itemsPrice,
  freeTransportLimit,
  transportPrice
) => (itemsPrice > 0 && itemsPrice < freeTransportLimit ? transportPrice : 0)

const Article = ({ product, removeProduct, title, refreshCart }) => {
  const {
    image,
    name,
    format,
    grammage,
    finish,
    cartons,
    price,
    discountAmount,
    discountPercent,
  } = product
  return (
    <article className="dt w-100 bb b--black-05 pb2 mt2" href="#0">
      <div className="dt fl w-100">
        <div className="dtc w-25 v-mid pl3">
          {image && (
            <div className="dib w2 w3-ns v-mid mr3">
              <img
                alt="Color Copy"
                className="ba b--black-10 db br2 w2 w3-ns h2 h3-ns"
                src={replace('http:', 'https:', image.url)}
              />
            </div>
          )}
          <div className="dib">
            <h1
              className={`f6 f5-ns fw6 lh-title black mv0 ${title ? 'b' : ''}`}
            >
              {name}
            </h1>
          </div>
        </div>
        <div className="dtc w-10 v-mid">
          <div className={`tc f6 f5-ns lh-title black mv0 ${title ? 'b' : ''}`}>
            {format}
          </div>
        </div>
        <div className="dtc w-10 v-mid">
          <div className={`tc f6 f5-ns lh-title black mv0 ${title ? 'b' : ''}`}>
            {grammage}
          </div>
        </div>
        <div className="dtc w-25 v-mid">
          <div className={`tc f6 f5-ns lh-title black mv0 ${title ? 'b' : ''}`}>
            {finish}
          </div>
        </div>
        <div className="dtc w-10 v-mid">
          <div className={`f6 f5-ns lh-title black mv0 ${title ? 'b' : ''}`}>
            {title ? (
              cartons
            ) : (
              <input
                style={{ width: '5em' }}
                type="number"
                value={cartons}
                onChange={e => {
                  const cartons = e.target.value
                  const body = {
                    ...product,
                    cartons,
                    ...countPrice(
                      product,
                      product.grammage,
                      product.format,
                      product.amount,
                      cartons,
                      discountAmount,
                      discountPercent
                    ),
                  }
                  fetch('/api/cart/update', {
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(body),
                  }).then(resp => {
                    refreshCart()
                    return resp.json()
                  })
                }}
              />
            )}
          </div>
        </div>
        <div className="dtc w-10 v-mid">
          <div className={`f6 f5-ns lh-title black mv0 ${title ? 'b' : ''}`}>
            {`${price}${title ? '' : ' kč'}`}
          </div>
        </div>
        <div className="dtc w-10 tc v-mid">
          {removeProduct && (
            <a
              className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
              href="#0"
              onClick={e => {
                e.preventDefault()
                removeProduct()
              }}
            >
              Odebrat
            </a>
          )}
        </div>
      </div>
    </article>
  )
}

const CartView = ({
  discountAmount,
  discountPercent,
  items,
  totalPrice,
  refreshCart,
  transportPrice,
  freeTransportLimit,
}) => {
  const recountedTransportPrice = recountTransportPrice(
    totalPrice,
    freeTransportLimit,
    transportPrice
  )
  return (
    <>
      <div className="pa4 mb4 productColumn__title productColumn__title--blue">
        Nákupní košík
      </div>
      <Article
        title
        product={{
          name: 'Produkt',
          format: 'Formát',
          grammage: 'Gramáž',
          finish: 'Úprava',
          cartons: 'Krabic',
          price: 'Cena',
        }}
      />
      {items
        ? items.map(item =>
            item ? (
              <Article
                key={item.key}
                product={item}
                discountAmount={discountAmount}
                discountPercent={discountPercent}
                refreshCart={refreshCart}
                removeProduct={() => {
                  fetch('/api/cart/delete', {
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(item),
                  }).then(() => {
                    refreshCart()
                  })
                }}
              />
            ) : null
          )
        : null}
      {recountedTransportPrice > 0 && (
        <Article
          key="deliveryPrice"
          title="Cena za dopravu"
          product={{
            name: 'Cena za dopravu',
            price: `${recountedTransportPrice} kč`,
          }}
        />
      )}
      <div className="pt4 ph3 w-100">
        <div className="dib w-50">
          <b>Celková cena:</b>&nbsp;{totalPrice + recountedTransportPrice}
          &nbsp;kč
        </div>
      </div>
    </>
  )
}

export default CartView
