import React from 'react'

import Layout from '../../layouts/layout'
import ShopingCart from '../../components/ShopingCart'

const ShopingCartPage = () => (
  <Layout>
    {({ cart, refreshCart }) => (
      <main className="w-100 pv2">
        <ShopingCart cart={cart} refreshCart={refreshCart} />
      </main>
    )}
  </Layout>
)

export default ShopingCartPage
