import React, { useState, useEffect } from 'react'
import reduce from 'lodash/fp/reduce'
import size from 'lodash/fp/size'

import CartView from './CartView'
import { promiseConfiguration } from '../../lib/configuration'

const countItemsPrice = items =>
  reduce((prev, curr) => prev + curr.price, 0, items)

const canSendCart = cart => size(cart.items) > 0

const ShopingCart = ({ cart, refreshCart }) => {
  const [cartState, setCartState] = useState({
    items: [],
  })
  const [transportPrice, setTransportPrice] = useState(200)
  const [freeTransportLimit, setFreeTransportLimit] = useState(5000)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)

  useEffect(() => {
    setCartState({
      ...cartState,
      items: cart.items,
      totalPrice: countItemsPrice(cart.items),
    })
  }, [cart])

  useEffect(() => {
    promiseConfiguration().then(configuration => {
      setTransportPrice(configuration.transportPrice)
      setFreeTransportLimit(configuration.freeTransportLimit)
      setDiscountAmount(configuration.discountAmount)
      setDiscountPercent(configuration.discountPercent)
      refreshCart()
    })
  }, [])

  return (
    <>
      <CartView
        discountAmount={discountAmount}
        discountPercent={discountPercent}
        items={cartState.items}
        totalPrice={cartState.totalPrice}
        transportPrice={transportPrice}
        freeTransportLimit={freeTransportLimit}
        refreshCart={refreshCart}
      />
      <div className="pv4">
        <div className="dib w-50 fr tr">
          <div>
            <a
              className={`f6 grow no-underline br-pill ph3 pv2 mb2 dib white ${
                canSendCart(cartState) ? 'bg-dark-blue' : 'bg-moon-gray'
              }`}
              href="/nakupni-kosik/adresa"
              onClick={e => {
                e.preventDefault()
                if (canSendCart(cartState)) {
                  document.location.replace('/nakupni-kosik/adresa')
                }
              }}
            >
              Dokončit objednávku
            </a>
          </div>
          <div>
            <a
              className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
              href="/"
            >
              Pokračovat v nákupu
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopingCart
